import React, { useState } from 'react';
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AutomationTemplates = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  // Sample data for templates
  const templates = [
    { id: 1, name: 'Plan set analyzer', discussion: 'AI-powered survey analysis with PDF uploads, chat, tabs, and exports', routes: '/planset-analyzer' },
    { id: 2, name: 'Survey Analyzer', discussion: 'AI-driven platform for efficient survey analysis and insightful reports.', routes: '/survey-analyzer' },
    { id: 3, name: 'AHJ Looker', discussion: 'Find Authority Having Jurisdiction (AHJ) information for a given address.', routes: '/ahj-looker' },
  ];

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Theme and media query hooks
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        padding: "30px 30px 0px 15px",// Reduced top padding
        backgroundColor: isDarkMode ? '#121212' : '#f4f4f4',
        minHeight: '100vh',
        transition: 'background-color 0.3s ease',
      }}
    >
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search Templates..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          mb: 3, // Reduced bottom margin
          backgroundColor: isDarkMode ? '#333' : '#fff',
          borderRadius: 1,
          '& .MuiInputBase-root': {
            color: isDarkMode ? '#fff' : '#000',
          },
        }}
      />
      <Grid container spacing={3}>
        {filteredTemplates.map((template) => (
          <Grid item xs={12} sm={6} md={4} key={template.id}>
            <Card
              sx={{
                borderRadius: 2,
                backgroundColor: isDarkMode ? '#1d1d1d' : '#fff',
                boxShadow: 2,
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={isDarkMode ? 'white' : 'primary'}
                  sx={{ mb: 1 }}
                >
                  {template.name}
                </Typography>
                <Typography
                  variant="body2"
                  color={isDarkMode ? 'textSecondary' : 'textPrimary'}
                >
                  {template.discussion}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    width: '100%',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() => navigate(template.routes)}
                >
                  Start
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AutomationTemplates;
