import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Link,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import CategoryIcon from '@mui/icons-material/Category';

const AHJLooker = () => {
  const [address, setAddress] = useState('');
  const [ahjData, setAhjData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLookup = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        'https://ahj.framesense.ai/api/lookup',
        { address },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setAhjData(response.data);
    } catch (error) {
      console.error('Error looking up AHJ:', error);
      setError('Failed to lookup AHJ. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Card elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
        <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2 }}>
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            AHJ Looker
          </Typography>
        </Box>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 3 }}>
            <LocationOnIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Enter Address"
              variant="outlined"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLookup}
              disabled={loading}
              sx={{ ml: 2, height: 56 }}
              startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
            >
              {loading ? 'Searching...' : 'Lookup AHJ'}
            </Button>
          </Box>

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          {ahjData && ahjData.data ? (
            <>
              <Typography variant="h5" gutterBottom sx={{ mt: 3, fontWeight: 'bold', color: 'primary.main' }}>
                AHJ Information
              </Typography>
              <TableContainer component={Paper} sx={{ mt: 2, mb: 4, borderRadius: 2, overflow: 'hidden' }}>
                <Table>
                  <TableHead sx={{ bgcolor: 'primary.main' }}>
                    <TableRow>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>AHJ Name</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>NEC Version</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>IBC Version</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>IRC Version</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>IFC Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ahjData.data && ahjData.data.length > 0 ? (
                      ahjData.data.map((item) => {
                        let codes = {};
                        try {
                          codes = JSON.parse(item.attributes.codes || '{}');
                        } catch (e) {
                          console.error('Failed to parse codes:', item.attributes.codes);
                        }
                        return (
                          <TableRow key={item.id}>
                            <TableCell sx={{ fontWeight: 'medium' }}>{item.attributes.ahjname || 'N/A'}</TableCell>
                            <TableCell>{item.attributes.nec || 'N/A'}</TableCell>
                            <TableCell>{codes.IBC_Version || 'N/A'}</TableCell>
                            <TableCell>{codes.IRC_Version || 'N/A'}</TableCell>
                            <TableCell>{codes.IFC_Version || 'N/A'}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'medium' }}>{ahjData.data?.ahj?.name || 'N/A'}</TableCell>
                        <TableCell>{ahjData.data?.codes?.NEC?.version || 'N/A'}</TableCell>
                        <TableCell>{ahjData.data?.codes?.IBC?.version || 'N/A'}</TableCell>
                        <TableCell>{ahjData.data?.codes?.IRC?.version || 'N/A'}</TableCell>
                        <TableCell>{ahjData.data?.codes?.IFC?.version || 'N/A'}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Environmental Data
              </Typography>
              <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CategoryIcon sx={{ color: 'primary.main', mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Exposure Category</Typography>
                        <Typography>
                          {ahjData.data.environmental?.exposure_category || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AcUnitIcon sx={{ color: 'primary.main', mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Snow Load</Typography>
                        <Typography>
                          {ahjData.data.environmental?.snow_load || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ThermostatIcon sx={{ color: 'primary.main', mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Ambient Temperature</Typography>
                        <Typography>
                          {ahjData.data.environmental?.ambient_temperature && typeof ahjData.data.environmental?.ambient_temperature === 'object'
                            ? `${ahjData.data.environmental.ambient_temperature.summer || 'N/A'} (summer) / ${ahjData.data.environmental.ambient_temperature.winter || 'N/A'} (winter)`
                            : ahjData.data.environmental?.ambient_temperature || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AirIcon sx={{ color: 'primary.main', mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Wind Speed</Typography>
                        <Typography>
                          {ahjData.data.environmental?.wind_speed || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : (
            ahjData && <Typography sx={{ mt: 2 }}>No AHJ data found for this address.</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default AHJLooker;
